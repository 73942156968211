<template>
  <td :class="{selected: displayEditor}">
    <div class="cell" v-if="['expert', 'rehabilitator'].includes(cellType)">
      {{ data.lastname }} {{ data.firstname }}
    </div>

    <div class="cell" v-else-if="cellType === 'agencies'">
      {{ data.join(', ') }}
    </div>
    <div class="cell" v-else-if="cellType === 'date'">
      {{ new Date(data).toLocaleString() }}
    </div>
    <div class="cell" v-else-if="cellType === 'address'">
      {{ data.streetNumber }} {{ data.street }},{{ data.postalCode }} {{ data.city }}
    </div>
    <div class="cell" v-else-if="cellType === 'boolean'" @click="onClick">
      {{ data ? 'oui' : 'non' }}
    </div>
    <div class="cell" v-else-if="cellType === 'select'" @click="onClick">
      {{ options.find(o=>o.value === data)? options.find(o=>o.value === data).name:'' }}
    </div>
    <div class="cell" v-else-if="cellType === 'file'" @click="onClick">
      <span v-if="data" class="button upload" @click.prevent="downloadFile">Visioner</span>
      <span v-if="data && editable" class="button upload"><EditIcon/></span>
      <span class="button upload" v-if="editable && !data">Envoyer un fichier</span>
    </div>

    <div class="cell" v-else @click="onClick">
      {{ data }}
    </div>

    <BottomBar v-if="displayEditor" v-clickout="close" class="bottomBar">
      <div v-if="cellType==='boolean'" class="line">
        <span>Non</span>
        <SwitchButton v-model="editedData"/>
        <span>Oui</span>
      </div>
      <Select v-if="cellType==='select'" :options="options" v-model="editedData" class="line"/>

      <Input
        v-else
        class="input"
        :type="typesAllowed.includes(cellType)?cellType:'text'"
        :title="cellTitle"
        v-model="editedData"
        ref="input"
      />

      <div class="row">
        <Button
          title="Sauvegarder"
          type="primary"
          @click="save"
        />
        <Button
          title="Annuler"
          type="warning"
          @click="close"
        />
      </div>
    </BottomBar>
  </td>
</template>

<script>
import BottomBar from '@/components/Layout/BottomBar'
import Input from '@/components/Forms/Fields/Input'
import Select from '@/components/Forms/Fields/Select'
import SwitchButton from '@/components/Forms/Fields/SwitchButton'
import Button from '@/components/Button'
import EditIcon from '@/assets/icons/edit.svg'

export default {
  name: 'MonitoringPatientCell',
  components: {
    BottomBar, Input, Button, EditIcon, SwitchButton, Select
  },
  props: {
    cellType: String,
    cellTitle: String,
    data: [Object, Array, String, Number, Boolean, Date],
    editable: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
    }
  },
  data () {
    return {
      displayEditor: false,
      editedData: null,
      typesAllowed: ['file','text','number'],
    }
  },
  created () {
    if(this.cellType !== 'file') {
      this.editedData = this.data
    }
  },
  methods: {
    onClick () {
      if (this.editable) {
        setTimeout(() => {
          this.displayEditor = !this.displayEditor
          this.$nextTick(()=>{
            this.$refs.input?.focus()
          })
        }, 10)
      }
    },
    downloadFile(){
      const link = document.createElement("a");
      link.href = this.data;
      link.target = "_blank";
      link.click();
    },
    uploadFile(){
      return this.$store.dispatch('admin/addPrivateFile', {file: this.editedData[0]})
    },
    async save () {
      if(this.cellType === 'file' && this.editedData){
        const {id} = await this.uploadFile();
        this.$emit('edit', id)
        this.close()
        return
      }
      this.$emit('edit', this.editedData)
      this.close()
    },
    close () {
      this.displayEditor = false
    }
  }
}
</script>

<style lang="scss" scoped>
td{
  &.selected{
    color: $main-color;
    box-shadow: 0px 0px 1px 0px $main-color inset;
  }
}

.cell{
  padding: 8px 10px;
  min-width: 150px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .button {
    cursor: pointer;
  }
  .upload svg {
    height: 32px;
  }
}
.line {
  display: flex;
  align-items: center;
}

.bottomBar{
  height: 100px;

  .input{
    flex-grow: 1;
  }
}
</style>
