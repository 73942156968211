<template>
  <div class="monitoring-display-bar">
    <div class="columns-selector">
      <div class="toggle-selectors">
        <Button @click="enableAll()" title="Tout" type="secondary" />
        <Button @click="disableAll()" title="Rien" type="secondary" />
      </div>
      <div class="column-selector" v-for="(column, i) in displayColumns" :key="i">
        <SwitchButton
          :title="column.title"
          :name="column.title"
          v-model="column.display"
          orientation="vertical"
        />
      </div>
    </div>
  </div>
</template>

<script>
import SwitchButton from '@/components/Forms/Fields/SwitchButton'
import Button from '@/components/Button'

export default {
  name: 'MonitoringDisplayBar',
  components: {
    SwitchButton, Button
  },
  props: ['columns'],
  methods: {
    enableAll () {
      Object.keys(this.columns).forEach(key => {
        if(!this.columns[key].hidden)
          this.columns[key].display = true
      })
    },
    disableAll () {
      Object.keys(this.columns).forEach(key => {
        if(!this.columns[key].hidden){
          this.columns[key].display = false
        }
      })
    }
  },
  computed: {
    displayColumns () {
      const cols = []
      Object.keys(this.columns).forEach(key => {
        if(!this.columns[key].hidden)
          cols.push(this.columns[key])
      })

      return cols
    }
  }
}
</script>

<style lang="scss" scoped>
.monitoring-display-bar{
  display: flex;
  margin: 10px 0;
  padding: 10px 0;

  .columns-selector{
    display: flex;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid $light-grey-color;

    .toggle-selectors{
      flex: 1;
      display: flex;
      justify-content: center;
      flex-direction: column;
      margin-right: 20px;

      .button{
        margin: 0;

        &:first-child{
          margin-bottom: 10px;
        }
      }
    }

    .column-selector{
      flex: 1;
      display: flex;
      justify-content: flex-end;
      flex-direction: column;

      .field{
        justify-content: flex-end;
      }
    }
  }
}
</style>
